<template>
  <div style="min-height: 500px;" class="collectionNotImg">
    <div v-if="enterpriseList&&enterpriseList.length">
      <div class="table">
        <div class="tableHeader m-t-25">
          <div class="cell-1">
          </div>
          <div class="cell-2">企业信息</div>
          <div class="cell-3">员工人数</div>
          <div class="cell-5">操作</div>
        </div>
        <div class="table-item" v-for="item in enterpriseList" :key="item.id">
          <div class="table-boby m-t-25">
            <div class="cell-1">
              <el-checkbox @change="handleCheckChange(item.id,item.isCheck)" v-model="item.isCheck" ></el-checkbox>
            </div>
            <div class="cell-2">
              <div class="cell-2-box">
                <img :src="$oucy.ossUrl+item.enterpriseAvata" alt class="goods-img" />
                <span class="goods-txt2">{{item.enterpriseName}}</span>
              </div>
            </div>
            <div class="cell-3 goods-txt3">{{item.enterpriseEmployee}}</div>

            <div class="cell-5 goods-txt5">
              <div class="cell-5-box">
                <el-button
                  size="mini"
                  class="cell-5-btn"
                  @click="collectEnterpriseToppingFn(item.id)"
                  :style="item.cardEnterpriseTopping?'background:#2090FF;color:#FFFFFF':''"
                >
                  <div>{{item.cardEnterpriseTopping?'取消置顶':'置顶'}}</div>
                </el-button>
                <el-button
                  size="mini"
                  class="cell-5-btn"
                  @click="$oucy.go('/enterpriseDetail?id='+item.enterpriseId)"
                >
                  <div>分享</div>
                </el-button>
                <el-button size="mini" class="cell-5-btn" @click="isCollectFn(item.enterpriseId)">
                  <div>删除</div>
                </el-button>
              </div>
            </div>
          </div>
          <div class="link"></div>
        </div>
      </div>
      <div class="bottom-cell-1">
        <div class="bottom-cell-2">
          <div class="bottom-select">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          </div>

          <div class="bottom-btn-box">
            <!-- <el-button size="mini" class="bottom-btn">
              <div>分享</div>
            </el-button>-->
            <el-button size="mini" class="bottom-btn" @click="allDelBtn">
              <div>删除</div>
            </el-button>
          </div>
        </div>
        <div>
          <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
            <el-pagination
              v-if="true"
              background
              layout="sizes, prev, pager, next, jumper, ->, total, slot"
              :page-size="10"
              :total="totalElements"
              @current-change="currentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="collection-not">
      <img src="@/assets/images/photo/collection_not.png" />
      <div class="c-2 m-t-50 f14">您还未收藏过任何商品/店铺/个人名片/企业名片~</div>
    </div>
  </div>
</template>

<script>
import { collectuser } from "@/service";

export default {
  created() {
    // console.log("挂载企业卡片");
    this.getCollectEnterprise();
  },

  data() {
    return {
      checkAll: false,
      enterpriseList: [], //收藏企业数据
      checkList:[], //多选框数据
      collectId: { id: "" },
      totalElements: null,
      paramsEnterprise: { limit: 10, start: 0 } //请求收藏企业参数
    };
  },
  methods: {
    // 请求：收藏的企业
    getCollectEnterprise(obj) {
      obj = this.paramsEnterprise;
      collectuser.getCardEnterpriseCollect(obj).then(res => {
        // console.log("企业名片", res);
        let { content, totalElements } = res;
             //企业数据增加isCheck字段
        this.enterpriseList = content&&content.map(item=>{
          item.isCheck = false
          return item
        });
        //企业
        this.enterpriseList = content;
        //总页数
        this.totalElements = totalElements;
      });
    },
    //企业单选框
    handleCheckChange(id,value) {
      console.log('value',value);
      console.log('id',id);
      if (value) {
        this.checkList.push(id)
      }else {
        let n = this.checkList.indexOf(id)
        this.checkList.splice(n,1)
      }
        console.log(this.checkList);
        this.checkAll = this.checkList.length === this.enterpriseList.length
    },
    //企业全选框
    handleCheckAllChange(val) {
      this.enterpriseList.forEach(item=>{
          item.isCheck = val
        });
         if (val) {
        this.checkList = []
        this.enterpriseList.forEach(item => {
        this.checkList.push(item.id); 
        })  
        }else{
          this.checkList = []
        }
        console.log("全选数组",this.checkList);
    },
    // 分页器:
    // 第几页
    currentChange(page) {
      this.paramsEnterprise.start = page - 1;
      collectuser.getCardEnterpriseCollect(this.paramsEnterprise).then(res => {
        let { content } = res;
        this.enterpriseList = content;
      });
    },
    //置顶
    collectEnterpriseToppingFn(id) {
      this.collectId.id = id;
      //点击置顶
      collectuser.collectEnterpriseTopping(this.collectId);
      //刷新页面
      this.getCollectEnterprise(this.paramsEnterprise);
    },
    //删除
   async isCollectFn(id) {
      this.collectId.id = id;
      console.log('单选参数',{ids:JSON.stringify([id])});
     await collectuser.batchCancelCardEnterpriseCollect({ids:JSON.stringify([id])});
      //刷新页面
      this.getCollectEnterprise(this.paramsEmployee);
    },
     //多全选删除
   async allDelBtn(){
      console.log("checkList",this.checkList);
      //全选删除
       console.log('全选参数',{ids:JSON.stringify(this.checkList)});
    await collectuser.batchCancelCardEnterpriseCollect({ids:JSON.stringify(this.checkList)})
       //刷新页面
      this.getCollectEmployee(this.paramsEmployee);
      this.checkList=[]
    }
  }
};
</script>

<style lang="less" scoped>
.collection-not{
  margin-top: 100px;
}
.link {
  border-top: 1px solid #f6f7f8;
  margin-top: 10px;
  margin-left: 20px;
}
.collectionNotImg {
  width: 100%;
  display: block;
  margin: 21px auto;
  .cell-1 {
    flex: 1;
  }

  .cell-2 {
    .cell-2-box {
      display: flex;
    }
    flex: 6;
    .goods-txt2 {
      width: 274.4px;
      height: 24.5px;
      line-height: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #555c68;
      text-align: left;
      margin: auto;
    }
  }

  .cell-3 {
    flex: 4;
  }
  .cell-5 {
    flex: 3;
  }
}
.tableHeader {
  width: 100%;
  display: flex;
  background: #fafafa;
  border-radius: 1.4px;
  padding: 7px;
  box-sizing: border-box;
  font-size: 9.8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #555c68;
}
.table-item {
  display: flex;
  flex-direction: column;
  .table-boby {
    display: flex;
    justify-content: center;
    align-items: center;
    .goods-img {
      width: 64px;
      height: 64px;
      border-radius: 2.8px 2.8px 2.8px 2.8px;
      opacity: 1;
    }
    .goods-txt3 {
      width: 40px;
      height: 13.3px;
      font-size: 9.8px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      line-height: 0px;
      margin: auto;
    }
    .goods-txt4 {
      width: 40px;
      height: 13.3px;
      font-size: 10px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: normal;
      // color: #dddddd;
      line-height: 0 px;
      margin: auto;
    }
    .goods-txt5 {
      .cell-5-box {
        display: flex;
        flex-direction: column;
        margin-left: -18px;
        padding-left: 7px;
        .cell-5-btn {
          margin: 5px auto;
          width: 75px;
          height: 25px;
          background: #f6faff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #cfe0f0;
          div {
            line-height: 5px;
          }
        }
      }
    }
    .el-button + .el-button {
      margin-left: 0px;
      margin-top: 5.6px;
    }
  }
}
.bottom-cell-1 {
  .bottom-cell-2 {
    .bottom-select {
      margin-left: 25.5px;
    }
    display: flex;
    margin-top: 25px;
  }
  display: flex;
  justify-content: space-between;
  .bottom-btn-box {
    margin-left: 20px;
    .bottom-btn {
      margin: auto 5px;
      width: 68px;
      height: 25.2px;
      background: #f6faff;
      border-radius: 1.4px 1.4px 1.4px 1.4px;
      opacity: 1;
      border: 1 px solid #cfe0f0;
      div {
        line-height: 4.9px;
        width: 22.4px;
        height: 15.2px;
        font-size: 14;
        padding: 3px 0 0 5px;
      }
    }
  }
}
</style>